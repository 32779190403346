// src/components/Resources.js

import React, { useEffect, useState } from 'react';
import '../styles/App.css';
import Header from '../Header';
import Footer from '../Footer';
import course1 from '../assets/course-1.jpg';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Resources = () => {
  const [resources, setResources] = useState([])
  const batchId = localStorage.getItem('bll-batch-id')
  const [selectedBatchId, setSelectedBatchId] = useState(batchId);
  // console.log("===>selectedBatchId",selectedBatchId)

  useEffect(() => {
    getResources()
  }, [selectedBatchId])

  const getResources = () => {
    axios.get(`/api/course/resources?batchId=${selectedBatchId}`)
      .then(function (response) {
        let result = response.data;
        if (result.success) {
          setResources(result.data)
        }
      })
  }

  return (
    <main>
      <Header selectedBatchId={selectedBatchId} onBatchChange={setSelectedBatchId} />
      <div className="content">
        <div className="section today-container">
          <h5 className="welcome-user">Resources</h5>
          <div className='row pt-3'>
            {
              resources.map(resource => {
                return (
                  <div className='col-md-3'>
                    <div className='card mb-4'>
                      <img class="card-img-top ss-img" src={resource.resource.thumbnail ? process.env.REACT_APP_S3_IMAGE_BASE_URL + resource.resource.thumbnail : "./assets/img/no-image.jpg"} alt="BrightLife Logo" />
                      <div class="card-body resource-title">
                        <h2>{resource.resource.title}</h2>
                        <p>{resource.resource.description}</p>
                        <div className='row align-items-center justify-content-between'>
                          <div className='col-md-12 col-12 mb-2'>
                            {
                              resource.resource.type === 'PDF' ?
                                <Link to={process.env.REACT_APP_S3_IMAGE_BASE_URL + resource.resource.link} className='downloadBtn' download>
                                  Download PDF
                                </Link>
                                :
                                <Link to={resource.resource.link} className='downloadBtn' target='_blank'>
                                  Go to link
                                </Link>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  // <div className='col-md-6'>
                  //   <div className='card mb-3'>
                  //     <div class="row g-0">
                  //       <div class="col-md-4">
                  //         <img src={resource.resource.thumbnail ? process.env.REACT_APP_S3_IMAGE_BASE_URL+resource.resource.thumbnail : "./assets/img/no-image.jpg"} class="img-fluid rounded-start object-fit-cover h-100" alt="BrightLife Logo" />
                  //       </div>
                  //       <div class="col-md-8">
                  //         <div class="card-body resource-title">
                  //           <h2>{resource.resource.title}</h2>
                  //           <p>{resource.resource.description}</p>
                  //           <div className='row align-items-center justify-content-between'>
                  //             <div className='col-md-5 col-6'>
                  // {
                  //   resource.resource.type === 'PDF' ? 
                  //   <Link to={process.env.REACT_APP_S3_IMAGE_BASE_URL+resource.resource.link} className='downloadBtn' download>
                  //     Download PDF
                  //   </Link>
                  //   : 
                  //   <Link to={resource.resource.link} className='downloadBtn' target='_blank'>
                  //     Go to link
                  //   </Link>
                  // }                                
                  //             </div>
                  //           </div>
                  //         </div>
                  //       </div>
                  //     </div>
                  //   </div>                
                  // </div>
                )
              })
            }
          </div>
        </div>
      </div>


      <Footer />
    </main >
  );
};

export default Resources;
