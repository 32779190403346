import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CustomizedAccordions({faqs}) {
  return (
    <div className='faq-section'>
      {
        faqs.length > 0 && faqs.map((faq, index) => {
          return (
            <Accordion defaultExpanded={index === 0 ? true : false}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography className='fs-18'>{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {typeof faq.answer === 'string' ? (
                  faq.answer.split('\n').map((paragraph, idx) => (
                    <Typography key={idx} style={{ marginBottom: '1em' }}>
                      {paragraph}
                    </Typography>
                  ))
                ) : (
                  faq.answer
                )}
              </AccordionDetails>
              
              {/* <AccordionDetails>
                <Typography>{faq.answer}</Typography>
               </AccordionDetails>  */}
            </Accordion>
          )
        })
      }
    </div>
  );
}
