// src/components/Today.js

import React, { useEffect, useState } from 'react';
import '../styles/App.css';
import Header from '../Header';
import Footer from '../Footer';
import axios from 'axios';
var moment = require('moment');

const Today = () => {
  const [episode, setEpisode] = useState('video loading')
  // const [batches, setBatches] = useState([]);
  // const [batchId, setBatchId] = useState();
  const batchId = localStorage.getItem('bll-batch-id')
  const [selectedBatchId, setSelectedBatchId] = useState(batchId);

  useEffect(() => {
    if(!selectedBatchId){
      getBatches()
    }
  }, [])

  const getBatches = () => {
    axios.get(`/api/user/batches`)
    .then(function (response) {
      let result = response.data;
      if(result.success){
        setSelectedBatchId(result.data[0].batchId)
        if(!localStorage.getItem('bll-batch-id')){
          localStorage.setItem('bll-batch-id', result.data[0].batchId)          
        }
        // setBatches(result.data)
      }
    })
  }

  useEffect(() => {
      getEpisode()
  }, [selectedBatchId])

  const getEpisode = () => {
    axios.get(`/api/episode/today?batchId=${selectedBatchId}&todayDate=${moment(new Date()).format('YYYY-MM-DD')}`)
    .then(function (response) {
      let result = response.data;
      if(result.success){
        setEpisode(result.data[result.data.length - 1])
      }
    })
  }

  const getCourse = () => {
    axios.get(`/api/episode/today?batchId=${selectedBatchId}&todayDate=${moment(new Date()).format('YYYY-MM-DD')}`)
    .then(function (response) {
      let result = response.data;
      if(result.success){
        setEpisode(result.data)
      }
    })
  }

  return (
    <main>        
      <Header  selectedBatchId={selectedBatchId} onBatchChange={setSelectedBatchId}/>
      <div className="content ss-content">
        <div className="section today-container">  
          {
            episode ? 
            <>
            <p className="video-number-title">Day - {episode?.episodeDay}</p>
            {
              episode?.videoUrl ? 
                <div className="">
                  {
                    episode?.videoUrl ? 
                    <iframe className='videoFrame'
                      width="100%" 
                      height="400" 
                      src={`${episode?.videoUrl}?playsinline=0`}
                      title={episode?.name}
                      // frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                      webkitAllowFullScreen
                      mozAllowFullScreen
                      allowFullScreen
                      referrerPolicy="strict-origin-when-cross-origin">
                    </iframe>
                    : ''
                  }                    
                </div>
              : ''
            }
            <p className='rj-para'>{episode?.description}</p>  
            </>
            : 
            <h2 className='mt-5 pt-5'>Course End</h2>
          }                 
                  
        </div>     
      </div>
      <Footer />
    </main>   
  );
};

export default Today;
