// src/components/Footer.js

import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  const whatsgroupurl = localStorage.getItem('bll-batch-url');
  // console.log("whatsgroupurl===>",whatsgroupurl)
  return (
    <footer>
      <div className="bottom-menu">
      <div className='Rj-Container'>
          <Link to='/faq' className={location.pathname === "/faq"? "menu-item active" : "menu-item"}>FAQ</Link>
          <Link to='/courses' className={location.pathname === "/courses"? "menu-item active" : "menu-item"}>Course</Link>
          <Link to='/today' className={location.pathname === "/today"? "menu-item active" : "menu-item"}>Today</Link>
          <Link to='/resources' className={location.pathname === "/resources"? "menu-item active" : "menu-item"}>Resources</Link>
          <Link to={whatsgroupurl} className="menu-item" target='_blank'>Community</Link>          
      </div>
      </div>
    </footer>
  );
};

export default Footer;

