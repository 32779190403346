// src/components/Faq.js

import React, { useEffect, useState } from 'react';
import '../styles/App.css';
import CustomizedAccordions from '../Accordions/CustomizedAccordions';
import Header from '../Header';
import Footer from '../Footer';
import axios from 'axios';
var moment = require('moment');

const Faq = () => {
  const [episodes, setEpisodes] = useState([])
  const batchId = localStorage.getItem('bll-batch-id')
  const [selectedBatchId, setSelectedBatchId] = useState(batchId);
  // console.log("===>selectedBatchId",selectedBatchId)

  useEffect(() => {
    getEpisodes()
  }, [selectedBatchId])

  const getEpisodes = () => {
    axios.get(`/api/episode/today?batchId=${selectedBatchId}&todayDate=${moment(new Date()).format('YYYY-MM-DD')}`)
    .then(function (response) {
      let result = response.data;
      if(result.success){
        setEpisodes(result.data)
      }
    })
  }

  return (
    <main>        
      <Header selectedBatchId={selectedBatchId} onBatchChange={setSelectedBatchId} />
        <div className="content">
          <div className="section today-container">
            <h5 className="welcome-user">Faqs</h5>
              {
                episodes.length > 0 && episodes.reverse().map(episode => {
                  return (
                    episode?.faqs && episode.faqs.length > 0? 
                    <>
                    <h4>Day - {episode.episodeDay}</h4>
                    <CustomizedAccordions faqs={episode?.faqs ? episode.faqs : []}/>
                    </>
                    : ''
                  )
                })
              }    
                
          </div>     
        </div>
        <Footer />    
    </main>   
  );
};

export default Faq;
