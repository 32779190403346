// src/App.js
import "./intercepter";
import React from 'react';
import { BrowserRouter as Router, Route, Switch, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login/Login';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import 'bootstrap/dist/css/bootstrap.min.css';

import Faq from './components/Pages/Faq';
import Courses from './components/Pages/Courses';
import Today from './components/Pages/Today';
import Resources from './components/Pages/Resources';
import Community from './components/Pages/Community';
import PublicRoutes from './components/Routes/PublicRoutes';
import ProtectedRoutes from './components/Routes/ProtectedRoutes';

function App() {
  return (
    <Routes>
        <Route path="/" element={<PublicRoutes />}>
          <Route path="/" element={<Login />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />}></Route>
        </Route>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<Navigate replace to="Today" />} />
          <Route element={<Faq />} path="/faq" />          
          <Route element={<Courses />} path="/courses" />
          <Route element={<Today />} path="/today" />
          <Route element={<Resources />} path="/resources" />
          <Route element={<Community />} path="/community" />
        </Route>        
      </Routes>
  );
}

export default App;
