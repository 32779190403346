// src/components/Header.js

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from './assets/brightlife-logo.png';
import axios from 'axios';
import Swal from 'sweetalert2';

const Header = ({ selectedBatchId, onBatchChange }) => {
  const authUser = localStorage.getItem('bll-authUser');
  const user = JSON.parse(authUser);
  const batchId = localStorage.getItem('bll-batch-id')  

  const navigate = useNavigate();

  const [batches, setBatches] = useState([]);

  useEffect(() => {
      getBatches()
  }, [])

  const getBatches2 = () => {
    axios.get(`/api/user/batches`)
      .then(function (response) {
        let result = response.data;
        if (result.success) {
          if(!localStorage.getItem('bll-batch-id')){
            localStorage.setItem('bll-batch-id', result.data[0].batchId)
            localStorage.setItem('bll-batch-url', result.data[0].batch.whatsappGroupUrl)
          }
          setBatches(result.data)
        }
      })
  }

  const getBatches = () => {
    const storedBatches = localStorage.getItem('bll-batches');
    // console.log("===>storedBatches",storedBatches)
    if (storedBatches) {
      const parsedBatches = JSON.parse(storedBatches);
      setBatches(parsedBatches);
    } else {
      axios.get(`/api/user/batches`)
        .then(function (response) {
          let result = response.data;
          if (result.success) {
            localStorage.setItem('bll-batches', JSON.stringify(result.data)); // Store array in localStorage            
            
              localStorage.setItem('bll-batch-id', result.data[0].batchId);
              localStorage.setItem('bll-batch-url', result.data[0].batch.whatsappGroupUrl);
                        
            setBatches(result.data);
          }
        })
        .catch(function (error) {
          console.error('Error fetching batches:', error);
        });
    }
  };

  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure you want to log out?',
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ebcc5c',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {         
        localStorage.clear();
        navigate("/");
      }
    })
  };

  const handleBatchChange = (event) => {
    // console.log("val===>",event.target.value);    
    const newBatchId = event.target.value;
    const selectedOption = event.target.selectedOptions[0];
    const batchUrl = selectedOption.dataset.url;
    // console.log("url===>",batchUrl);
    onBatchChange(newBatchId);
    localStorage.setItem('bll-batch-id', newBatchId);
    localStorage.setItem('bll-batch-url', batchUrl);    
  };


  return (
    <div className=''>
      {/* <header className="header">
        <div className='Rj-Container'>
          <div className="logo">
            <Link to='/Today'>
              <img src={logo} alt="BrightLife Logo" />
            </Link>
          </div>
          <div className='ss-blank'>
            &nbsp;
          </div>
          <div className='w-300 mx-auto mb-sm-20'>
            <select class="form-select" aria-label="Default select example" value={batchId}>
              <option disabled>Select Parenting Program</option>
              {
                batches.map((batch) => {
                  return (
                    <option value={batch.batchId}>{batch.batch.name}</option>
                  )
                })
              }
            </select>
          </div>
          <div className='Rj-align'>
            <h5 className="welcome-username mb-0">Welcome {user?.name}</h5>
            <button className='Rj-button' onClick={handleLogout}> Sign out</button>
          </div>
        </div>
      </header> */}
      <header className="header">
        <div className='Rj-Container d-block' style={{overflow: 'unset'}}>
          <div className='row align-items-center'>
            <div className='col-md-2 col-3 order-1 order-md-1'>
              <div className="logo">
                <Link to='/Today'>
                  <img src={logo} alt="BrightLife Logo" />
                </Link>
              </div>
            </div>
            <div className='col-md-3 col-12 offset-md-3 order-3 order-md-2'>
              <div className='w-300'>
                <select class="form-select" aria-label="Default select example" value={selectedBatchId} onChange={handleBatchChange}>
                  <option disabled selected>Select</option>
                  {
                    batches.map((batch) => {
                      return (
                        <option value={batch.batchId} data-url={batch.batch.whatsappGroupUrl}>{batch.batch.name}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
            <div className='col-md-4 col-9 order-2 order-md-3'>
              <div className='Rj-align justify-content-end'>
                <h5 className="welcome-username mb-0">Welcome <span>{user?.name}</span></h5>
                <button className='Rj-button d-none' onClick={handleLogout}> Sign out</button>
                <button className='ss-btn' onClick={handleLogout}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 512 512"><path fill="black" d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32v224c0 17.7 14.3 32 32 32s32-14.3 32-32zm-144.5 88.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z" /></svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
