// src/components/Course.js

import React, { useEffect, useRef, useState } from 'react';
import '../styles/App.css';
import Header from '../Header';
import Footer from '../Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import axios from 'axios';


const Course = () => {
    const [course, setCourse] = useState()
    const batchId = localStorage.getItem('bll-batch-id')
    const itemRefs = useRef([]);
    const btnRefs = useRef([]);
    const [selectedBatchId, setSelectedBatchId] = useState(batchId);
    // console.log("===>selectedBatchId",selectedBatchId)

    const hideElement = (index) => {
        if (itemRefs.current[index]) {
          itemRefs.current[index].style.display = 'none';
        }
        if (btnRefs.current[index]) {
            btnRefs.current[index].style.display = 'inline-block';
          }
    }

    const showElement = (index) => {
        if (itemRefs.current[index]) {
          itemRefs.current[index].style.display = 'inline-block';
        }
        if (btnRefs.current[index]) {
            btnRefs.current[index].style.display = 'none';
          }
    }

    useEffect(() => {
        getCourse()
      }, [selectedBatchId])
    
      const getCourse = () => {
        axios.get(`/api/course/today?batchId=${selectedBatchId}`)
        .then(function (response) {
          let result = response.data;
          if(result.success){
            setCourse(result.data)
          }
        })
      }

    return (
        <main>
            <Header selectedBatchId={selectedBatchId} onBatchChange={setSelectedBatchId} />
            <div className="content">
                <div className="section today-container">
                    <h5 className="welcome-user">Course</h5>
                    <h6>{course?.name}</h6>
                    <div className='row'>
                    {
                        course?.episodes && course.episodes.map((episode, index) => {
                            return (
                                <div className='col-md-6'>
                                    <div className='card mb-3'>
                                        <div class="row g-0">
                                            <div class="col-md-4">
                                                {/* <img src={episode.thumbnail ? process.env.REACT_APP_S3_IMAGE_BASE_URL+episode.thumbnail : "./assets/img/no-image.jpg"} class="img-fluid rounded-start object-fit-cover h-100" alt="BrightLife Logo" /> */}
                                                <img src={episode.thumbnail ? process.env.REACT_APP_S3_IMAGE_BASE_URL+episode.thumbnail : "./assets/img/no-image.jpg"} class="ss-img" alt="BrightLife Logo" />
                                            </div>
                                            <div class="col-md-8">
                                                <div class="card-body resource-title">
                                                    {/* <h1>{episode.episodeDay === 'Intro' || episode.episodeDay === 'End' ? episode.episodeDay : 'Day - '+episode.episodeDay}</h1> */}
                                                    <h2>{episode.name}</h2>
                                                    {
                                                        episode.description.length > 400 ? 
                                                        <p>                                                            
                                                            <span ref={(el) => (btnRefs.current[index] = el)}>{episode.description.substr(0, 400)}<Link className='moreLink' onClick={() => showElement(index)}>...more</Link></span>
                                                            <span style={{display: "none"}} ref={(el) => (itemRefs.current[index] = el)}>{episode.description}<Link className='moreLink' onClick={() => hideElement(index)}>...less</Link></span>
                                                        </p>
                                                        : <p>{episode.description}</p>

                                                    }
                                                    <div className='row align-items-center justify-content-between'>
                                                        <div className='col-md-5 col-6'>
                                                        {/* <div className='ss-btn'>
                                                            <span className=''>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="black" d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11zm-6 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z" /></svg>
                                                            </span> */}
                                                            {/* <Link className='downloadBtn'>Faq</Link> */}
                                                        {/* </div> */}
                                                        </div>
                                                        <div className='col-md-5 col-6'>
                                                        {/* <div className='ss-btn'>
                                                            <span className=''>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32"><path fill="black" d="M24 21V9h-2v14h8v-2zm-4-6v-4c0-1.103-.897-2-2-2h-6v14h2v-6h1.48l2.335 6h2.145l-2.333-6H18c1.103 0 2-.897 2-2m-6-4h4v4h-4zM8 23H4c-1.103 0-2-.897-2-2V9h2v12h4V9h2v12c0 1.103-.897 2-2 2" /></svg>
                                                            </span> */}
                                                            {/* <Link className='downloadBtn'>URL Link</Link> */}
                                                        {/* </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>                    
                </div>
            </div>
            <Footer />
        </main>
    );
};

export default Course;
