import React from 'react';
import '../styles/Login.css';
import heroBackground from '../assets/hero-background.jpg';
import logo from '../assets/brightlife-logo.png';
import { Link } from 'react-router-dom';

const Login = () => {
    return (
        <div className="login-wrapper">
            <div className="hero-section" style={{ backgroundImage: `url(${heroBackground})` }}>
                <div className="hero-content">
                    <h1>Welcome to BrightLife!<br/></h1>
                    <h2>Brightlife is a place for you to work on you & your life</h2>
                    <p>Experience the best with our new features.</p>
                </div>
            </div>
            <div className="login-form-container">
                <div className="logo">
                    <Link to= '/Today'><img src={logo} alt="BrightLife Logo" /></Link>
                </div>
                <form className="login-form">
                    <h2>Forgot Password? 🔒</h2>
                    <p>Enter your email and we'll send you instructions to reset your password</p>
                    <div className="input-group">
                        <input type="text" id="username" placeholder="Email or Username" required />
                    </div>                   
                    <Link to= '/Today'><button type="submit" className="login-button">Send Reset Link</button></Link>
                    <div className="forgot-password">
                        <Link to= '/Login'> Back to Login</Link>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
