import React, { useState, useEffect } from 'react';
import '../styles/Login.css';
import heroBackground from '../assets/hero-background.jpg';
import logo from '../assets/brightlife-logo.png';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';

const isPhoneNumberExist = async (phone) => {
    let user = await axios.get(`/api/users/isPhoneExist?phone=${phone}`)
    
    if(user.data.success && user.data.isExist === true){
        return true            
    }
    else{
        return false 
    }
}

const validationSchema = yup.object({
    phone: yup
        .number('Enter registered mobile number')
        .required('Mobile Number is required')
        .test('phone check', 'Mobile Number is not registered', value => isPhoneNumberExist(value)),
});

const Login = () => {
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [timer, setTimer] = useState(600);
    const [isResendEnabled, setIsResendEnabled] = useState(false);
    const [isUserDeleted, setIsUserDeleted] = useState(false);
    const navigate = useNavigate();

    const initialValues = {
        phone: '',
        password: '',
    };

    useEffect(() => {
        let interval;
        if (isOtpSent && timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else if (timer === 0 && isOtpSent) {
            deleteUser(); // Automatically delete the session
        }
        return () => clearInterval(interval);
    }, [isOtpSent, timer]);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    const handleSubmit = (values) => {
        if (isOtpSent) {
            axios.post('/api/user/userLogin', values).then((res) => {
                if (res.data.success) {
                    localStorage.setItem('bll-authUser', JSON.stringify(res.data.data));
                    if (res.data.data.role === 'User') {
                        navigate('/today');
                    }
                }
            });
        } else {
            sendOtp(values);
        }
    };

    const sendOtp = (values) => {
        axios.post('/api/user/sendLoginOtp', values).then((res) => {
            if (res.data.success) {
                setIsOtpSent(true);
                setTimer(600);
                setIsResendEnabled(false);
                setIsUserDeleted(false);
            }
        });
    };

    const resendOtp = () => {
        const { phone } = formik.values;
        if (phone) {
            sendOtp({ phone });
            setIsResendEnabled(false); // Temporarily disable resend
            setTimer(600); // Restart the timer
        }
    };

    const deleteUser = async () => {
        const { phone } = formik.values;
        if (phone) {
            try {
                const res = await axios.post('/api/user/deleteLoginOtp', { phone });
                if (res.data.success) {
                    setIsUserDeleted(true);
                    setIsOtpSent(false); // Reset OTP sent status
                    setIsResendEnabled(true); // Enable the Resend OTP button immediately
                    setTimer(0); // Reset timer
                }
            } catch (error) {
                console.error('Failed to delete user session.');
            }
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    return (
        <div className="login-wrapper">
            <div className="hero-section" style={{ backgroundImage: `url(${heroBackground})` }}>
                <div className="hero-content">
                    <h1>Welcome to BrightLife!<br /></h1>
                    <h2>Brightlife is a place for you to work on you & your life</h2>
                    <p>Experience the best with our new features.</p>
                </div>
            </div>
            <div className="login-form-container">
                <div className="logo">
                    <Link to='/Today'><img src={logo} alt="BrightLife Logo" /></Link>
                </div>
                <form className="login-form" onSubmit={formik.handleSubmit}>
                    <h2>Login to Your Account</h2>
                    <div className="input-group">
                        <input
                            type="text"
                            name="phone"
                            readOnly={isOtpSent}
                            placeholder="Enter registered mobile number"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.phone && formik.errors.phone && (
                            <div className="text-danger">{formik.errors.phone}</div>
                        )}
                    </div>
                    {isOtpSent && (
                        <>
                            <div className="input-group">
                                <input
                                    type="password"
                                    id="password"
                                    placeholder="Enter the OTP"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <div className="text-danger">{formik.errors.password}</div>
                                )}
                            </div>
                            <button type="submit" className="login-button">
                                <i className="fa fa-long-arrow-right"></i> Log In
                            </button>
                        </>
                    )}
                    {!isOtpSent && (
                        <button type="submit" className="login-button">
                            <i className="fa fa-long-arrow-right"></i> Send OTP
                        </button>
                    )}
                    {isOtpSent && !isResendEnabled && (
                        <p
                            style={{
                                fontSize: '14px',
                                textAlign: 'center',
                                marginTop: '10px',
                                color: 'gray',
                            }}
                        >
                            Resend OTP in {formatTime(timer)}
                        </p>
                    )}
                    {isResendEnabled && (
                        <button
                            type="button"
                            onClick={resendOtp}
                            className="login-button"
                            style={{ marginTop: '10px' }}
                        >
                            Resend OTP
                        </button>
                    )}
                    <p className="m-3">Having trouble logging in? WhatsApp us at 9971837450</p>
                </form>
            </div>
        </div>
    );
};

export default Login;
