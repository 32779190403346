// src/components/Community.js

import React from 'react';
import '../styles/App.css';
import Header from '../Header';
import Footer from '../Footer';

const Community = () => {
  return (
    <main>        
      <Header />
        <div className="content">
          <div className="section today-container">
            <h5 className="welcome-user">Community</h5>            
            
          
          </div>     
        </div>
        <Footer />   
    </main>   
  );
};

export default Community;
